<template>
    <div class="overflow-hidden" style="background: #000000;">

        <div class="grid py-4 px-4 lg:px-8 relative" id="inicio" >
            <div class="mx-4 md:mx-8 mt-0 md:mt-4">
                <h1 class="text-6xl font-bold text-gray-800 line-height-2"><span class="font-light block">Comienza YA</span>Aplica el principio KISS</h1>
                <p class="font-normal text-3xl line-height-3 md:mt-3 text-gray-700">Keep It Simple, Stupid!. Hazlo Simple, Estúpido! ... </p>
            </div>
            <img src="images/curso_41.png" class="bottom-0" alt="hero screen" style="right:10%; top: 150px;">
        </div>

        <div class="py-0 px-4 lg:px-2 mt-5 mx-0 lg:mx-8" id="oferta">
            <div class="grid justify-content-center">

                <div class="col-12 mt-6 mb-8 p-2 md:p-8" style="border-radius:20px; background:linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), radial-gradient(77.36% 256.97% at 77.36% 57.52%, #EFE1AF 0%, #C3DCFA 100%);">
                    <div class="flex flex-column justify-content-center align-items-center text-center px-3 py-3 md:py-0">


                        <div class="grid justify-content-center">
                            <div class="col-12 p-0  md:col-3">
                                <img src="images/qr-code.png" class="bottom-0" alt="hero screen" style="right:10%; width: 100%;">
                            </div>
                            <div class="col-12 p-1  md:col-9">
                                <h3 class="text-gray-900 mb-2">CURSOS EN LINEA</h3>
                                <p class="text-gray-900 sm:line-height-2 md:line-height-4 text-2xl mt-4" style="max-width:800px;">Oferta de cursos que sirven de herramienta teórica y práctica para adquirir conocimientos que permiten
                                            entender el protocolo Bitcoin, la BlockChain y su usabilidad.</p>
                                <p class="text-gray-900 sm:line-height-2 md:line-height-4 text-2xl mt-4" style="max-width:800px;">“Preguntas a través de telegram”</p>
                                <p class="sm:line-height-2 md:line-height-4 mt-4" style="max-width:800px;">
                                    <a class="text-orange-500 " href="https://t.me/+4EtRDtnliiZkYWEx" target="_blank" ><span>🔥 Enlace a Telegram. Comienza YA 🔥</span></a>
                                </p>
                               
                                <p class="text-orange-800 sm:line-height-2 md:line-height-4 text-2xl mt-4" style="max-width:800px;">Si requiere cursos para grupos de manera presencial, por favor llene el formulario al pie de la página y en tipo seleccione la opción cursos.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="py-0 px-4 lg:px-8 my-2 md:my-4" id="cursos">

            <div class="grid fluid justify-content-between  mt-3">

                <div v-for="(item, id) in cursos" :key="id" class="col-12 lg:col-4 p-0 md:p-3">

                    <div class="p-3 flex flex-column border-200 hover:border-orange-200" style="border:2px solid; border-radius:10px;">
                        <h3 class="text-orange-500 text-center  text-white">{{item.titulo}}</h3>
                        <img :src="'images/'+item.foto" class="w-10 h-10 mx-auto" alt="">
                        <div class="my-5 text-center" >
                            <span class="text-5xl font-bold mr-2 text-900 text-white">${{item.precio}}</span>
                            <span class="text-600 text-white">por participante</span>
                                <Button label="Contratar" @click="ver(item)" class="text-white text-900 block mx-auto mt-5 p-button-rounded p-button-secondary border-none ml-3 font-light line-height-2"></button>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <div class="py-4 px-4 lg:px-8 mt-5 mx-0 lg:mx-8" id="asesoria">
            <div class="grid justify-content-center">
                <div class="col-12 mt-8 mb-8 p-2 md:p-8" style="border-radius:20px; background:linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), radial-gradient(77.36% 256.97% at 77.36% 57.52%, #EFE1AF 0%, #C3DCFA 100%);">
                    <div class="flex flex-column justify-content-center align-items-center text-center px-3 py-3 md:py-0">
                        <h3 class="text-gray-900 mb-2">ASESORIA</h3>
                        <p class="text-gray-900 sm:line-height-2 md:line-height-4 text-2xl mt-4" style="max-width:800px;">“Nuestro equipo ofrece asesorias especializadas en el área de señales para trading, gestión financiera y desarrollo de proyectos de software, entre otros.”</p>
                        <a class="flex align-items-center ml-2 mr-8">
                        <a class="text-orange-500" href="https://t.me/+4EtRDtnliiZkYWEx" target="_blank"><span>🔥 Enlace a Telegram. Comienza YA 🔥</span></a>
                        </a>
                        <p class="text-gray-900 sm:line-height-2 md:line-height-4 text-2xl mt-4" style="max-width:800px;">“Si le interesa cualquiera de estos productos. Por favor rellene el formulario con una breve descripción de sus necesidades”</p>

                    <div class="card">
                        <h5>Formulario</h5>
                        <div class="p-fluid formgrid grid">
                            <div class="field col-12 md:col-6">
                                <label for="name">Email</label>
                                <InputText id="name" v-model.trim="formulario.email" required="true" type="email"/>
                                <small class="p-invalid" v-if="!formulario.email">Es requerido</small>
                            </div>
                            <div class="field col-12 md:col-2">
                                <label>Celular</label>
                                <InputMask v-model="formulario.celular" mask="+99 999 9999999" />
                            </div>
                            <div class="field col-12 md:col-4">
                                <label for="state">Tipo</label>
                                <Dropdown id="state" v-model="formulario.tipo" :options="tipos" optionLabel="name" placeholder="Select One"></Dropdown>
                            </div>
                            <div class="field col-12">
                                <label for="address">Descripción</label>
                                <Textarea id="address" v-model="formulario.descripcion" rows="4"/>
                            </div>
                        </div>
                        <Button label="Enviar" @click="Formulario" class="text-white text-900 block mx-auto mt-5 p-button-rounded p-button-secondary border-none ml-3 font-light line-height-2"></button>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <Dialog :header="$t('Curso')" v-model:visible="$store.state.pago" @update:visible="close" :style="{width: size}" :modal="true" :maximizable="true" style="background: #727376;position: absolute;">
        <ReportPago :curso="curso" />
    </Dialog>

    </div>
</template>

<script>
import API from "../service/API";
const Consulta = new API('LandingPage','Basico');
import ReportPago from '../components/ReportPago.vue';


export default {
	props: {
		navegar: {
			type: String,
			default: null
		}
	},
	components: {
        ReportPago,
        
    },
	data() {
		return {
            dialog: false,

			d_navegar: this.navegar,
            temporal: null,
            size: '60vw',
            curso: {
                    titulo: null,
                    foto: null,
                    precio: 0,
                    contenido: null
            },
            cursos: [
                {
                    titulo: 'Bitcoin y BlockChain',
                    foto: 'curso_1.png',
                    precio: 20,
                    pdf: 'BitcoinyBlockChain.pdf',
                    contenido: "<p>Permite a los participantes conocer los principios básicos del protocolo Bitcoin y la blockchain, así como los elementos involucrados para su usabilidad dentro del ecosistema de economía circular.</p>"
                },
                {
                    titulo: 'Seguridad y KYC',
                    foto: 'curso_2.png',
                    precio: 20,
                    pdf: 'SeguridadyKYC.pdf',
                    contenido: "<p>Permite a los participantes conocer elementos básicos de seguridad presentes en el ecosistema Bitcoin y la blockchain, así como los elementos importantes relacionados con la privacidad  dentro del ecosistema de economía circular.</p>"
                },
                {
                    titulo: 'Exchange y P2P',
                    foto: 'curso_3.png',
                    precio: 20,
                    pdf: 'ExchangeyP2P.pdf',
                    contenido: "<p>Permite a los participantes conocer elementos básicos para el uso de las herramientas disponibles en los exchange, así como las consideraciones de seguridad que deben tomar al momento de involucrarse en una operación P2P</p>"
                },
                {
                    titulo: 'Estrategia de Inversión DCA',
                    foto: 'curso_4.png',
                    precio: 20,
                    pdf: 'EstrategiadeInversionDCA.pdf',
                    contenido: "<p>Permite a los participantes entender los elementos básicos de inversión a mediano y largo plazo utilizando la estrategia Dolar Cost Average de manera pasiva y activa en pro de maximizar el rendimiento de su inversión.</p>"
                },
                {
                    titulo: 'Configuración de Wallet',
                    foto: 'curso_5.png',
                    precio: 60,
                    pdf: 'ConfiguraciondeWallet.pdf',
                    contenido: "<p>Permite a los participantes a través de un proceso guiado entender el funcionamiento, instalación y configuración de la wallet autocustodial para bitcoin Sparrow.</p>"
                },
                {
                    titulo: 'Transacciones',
                    foto: 'curso_6.png',
                    precio: 20,
                    pdf: 'Transacciones.pdf',
                    contenido: "<p>Permite a los participantes entender los elementos básicos involucrados en una transacción de Bitcoin y su ejecución. Por otra parte, se presentan las herramientas para realizar el seguimiento de las transacciones que permitan verificar y validar su ejecución.</p>" 
                },
                {
                    titulo: 'Trading Básico',
                    foto: 'curso_9.png',
                    precio: 125,
                    pdf: '',
                    contenido: "<p>Obtendrás toda la conceptualización y los fundamentos básicos sobre el Trading con Criptomonedas, para potenciar tu conocimiento y aplicarlo en el mercado.</p>"
                },
                {
                    titulo: 'Trading Intermedio',
                    foto: 'curso_10.png',
                    precio: 155,
                    pdf: '',
                    contenido: "<p>Conocerás toda la información de los distintos exchanges, su interfaz, el sistema KYC y mucho mas para que puedas operar los distintos mercados de criptoactivos.</p>"
                },
                {
                    titulo: 'Trading Avanzado',
                    foto: 'curso_11.png',
                    precio: 350,
                    pdf: '',
                    contenido: "<p>Tendrás la oportunidad de armar una estrategia para establecer una operativa acorde con tu capital, utilizando las herramientas necesarias que te lleven a obtener rentabilidad en el mercado.</p>"
                },
            ],

			tipos: [
                {name: 'Asesoria Financiera', code: 1},
				{name: 'Cursos Presenciales', code: 1},
				{name: 'Proyectos', code: 2},
				{name: 'Señales', code: 3}
			],
            formulario: {
                email: null,
                celular: null,
                descripcion: null,
                tipo: null,
            },

		}
	},
    created() {
        if (this.$store.state.mobile){this.size = '80vw';}
    },
	watch: {
		navegar(newValue) {
			this.smoothScroll(newValue);
		}
	},
    methods: {
        VerPDF(document){
            window.open('/pdf/'+document, '_blank');
		},
        smoothScroll(id){
            const ini = document.querySelector('#inicio');
			const el = document.querySelector('#'+id);
			var y = el.getBoundingClientRect().top - 70;
            const med = ini.getBoundingClientRect().top - 70;
            if(this.temporal != 'inicio' && id != 'inicio'){
                y = y - med;
            }
			window.scrollTo({top: y, behavior: 'smooth'});
            this.temporal = id;
        },
        ver(curso){
            this.curso = curso;
            this.$store.state.pago = true;
        },
        Formulario() {
            if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.
					test(this.formulario.email)) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: this.$t('Error en Correo'), life: 10000});
				} else if (this.formulario.tipo == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: this.$t('Error en Tipo'), life: 5000});
				} else if (this.formulario.descripcion == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: this.$t('Error en Descripción'), life: 5000});
				} else {
                    Consulta.Procesar('Formulario',this.formulario).then(response => {
                        //this.$store.state.error =response;
                        this.$store.commit('Loading');
                        this.$toast.add(Consulta.Mens(response.result));
                        if (response.result) {
                            this.formulario = {
                                email: null,
                                celular: null,
                                descripcion: null,
                                tipo: null,
                            };
                        }
                    });
                }
		},

    },
    computed: {
        logoColor() {
            if (this.$appState.darkTheme) return 'white';
            return 'dark';
        },


    }
}
</script>

<style>
#inicio{
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), radial-gradient(77.36% 256.97% at 77.36% 57.52%, #F6911D 0%, #F6911D 100%);
    height:700px;
    overflow:hidden;
}

@media screen and (min-width: 768px) {
    #inicio{
        -webkit-clip-path: ellipse(150% 87% at 93% 13%);
        clip-path: ellipse(150% 87% at 93% 13%);
        height: 550px;
    }
}

@media screen and (min-width: 1300px){
    #inicio > img {
        position: absolute;
    }

    #inicio > div > p { 
        max-width: 450px;
    }
}

@media screen and (max-width: 1300px){
    #inicio {
        height: 600px;
    }

    #inicio > img {
        position:static;
        transform: scale(1);
        margin-left: auto;
    }

    #inicio > div {
        width: 100%;
    }

    #inicio > div > p {
        width: 100%;
        max-width: 100%;
    }
}
</style>

