<template>
    <div class="fluid">    

        <div class="grid fluid mt-3">
            <div class="col-12 md:col-5 border-black ml-5">
                <Card>
                    <template #header>
                        <img :src="'images/'+curso.foto" style="height: 15rem" />
                    </template>
                    <template #title>
                        {{curso.titulo}}
                    </template>
                    <template #subtitle>
                        <span class="text-5xl font-bold mr-2 text-900">${{curso.precio}}</span>
                        <span class="text-600">por participante</span>
                         <hr class="my-3 mx-0 border-top-2 border-none surface-border" />
                        </template>
                        <template #content>
                            <p v-html="curso.contenido"></p>
                    </template>
                    <template #footer>
                        <Button type="button" label="Contenido" class="p-button-warning mb-2" icon="pi pi-file-pdf" @click="VerPDF(curso.pdf)" v-if="curso.pdf != ''"/>
                    </template>
                </Card>     
            </div>
            
            <div class="col-12 md:col-6">
                <Card>
                    <template #content >
                        <div class="grid justify-content-between mt-8 md:mt-0">
                            <div class="col-12 lg:col-6 p-0 md:p-3" v-if="!datos.ver">
                                <div class="p-3 flex flex-column border-300 hover:border-orange-200" style="border:2px solid; border-radius:10px;" @click="datos.ver = true">
                                    <div class="my-2 text-center">
                                        <Button icon="pi pi-plus" class="p-button-rounded p-button-secondary p-button-outlined" />
                                    </div>
                                    <div class="my-2 text-center">
                                        <span class="text-600">Agregar Datos</span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 lg:col-12 p-0 md:p-3" v-if="datos.ver">
                                <div class="p-3 flex flex-column border-300 hover:border-orange-200" style="border:2px solid; border-radius:10px;">
                                    <div class="p-fluid formgrid grid">
                                        <div class="field col-12 md:col-12 lg:col-7">
                                            <label for="firstname2">Nombre</label>
                                            <InputText id="firstname2" v-model="datos.name" type="text"/>
                                        </div>
                                        <div class="field col-12 md:col-12 lg:col-5">
                                            <label for="address">Celular</label>
                                            <InputMask v-model="datos.celular" mask="+99 999 9999999" />
                                        </div>
                                        <div class="field col-12 md:col-12 lg:col-12">
                                            <label for="city">Correo</label>
                                            <InputText id="city"  v-model="datos.email" type="email" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <SplitButton label="Método de Pago" icon="pi pi-wallet" class="p-button-rounded p-button-warning mb-2" :model="formapago"></SplitButton>

                        <div class="grid justify-content-between mt-8 md:mt-0">
                            <div class="col-12 md:col-6 lg:col-6" v-if="seleccion !=null">
                                <img src="@/assets/images/cuenta_binance.jpg" class="w-10 h-10 mx-auto" v-if="seleccion.code==1"/>
                                <img src="@/assets/images/pago_movil.jpg" class="w-10 h-10 mx-auto" v-if="seleccion.code==2"/>
                            </div>
                            <div class="col-12 md:col-6 lg:col-6" v-if="seleccion !=null">
                                <p v-html="seleccion.detalle"></p>
                            </div>
                        </div>
                        <div class="p-fluid formgrid grid" v-if="seleccion !=null">
                            <div class="field col-12">
                                <label for="referencia">Referencia</label>
                                <InputText id="referencia"  v-model="datos.referencia" type="text" />
                            </div>
                            <div class="field col-6">
                                <label for="fecha">Fecha</label>
                                <Calendar inputId="dateformat" v-model="datos.fecha" dateFormat="dd-mm-yy" />
                            </div>
                            <div class="field col-6">
                                <label for="referencia">Monto {{moneda}}</label>
                                <InputNumber v-model="datos.monto" required="true" min="1" :minFractionDigits="2" disabled/>
                            </div>
                        </div>
                    </template>
                    <template #footer>
                        <Button icon="pi pi-check" label="Procesar" class="p-button-secondary" style="margin-left: .5em" @click="Procesar"/>
                        <Button icon="pi pi-times" label="Cerrar" class="p-button-danger" style="margin-left: .5em" @click="$store.state.pago=false" />
                    </template>
                </Card>
            </div>
        </div>
    </div>
</template>

<script>
import API from "../service/API";
const Consulta = new API('LandingPage','Basico');

export default {
	props: {
		curso: {
			type: Array,
			default: null,
		}
	},
	data() {
		return {
            datos: {
                ver: false,
                name: null,
                email: null,
                celular: null,
                fecha: null,
                referencia: null,
                monto: null,
            },
            formapago: [
                {
                    label: 'Binance',
                    icon: 'pi pi-send',
                    command: () => {
                        this.Seleccion(0);
                    }
                },
                {
                    label: 'Tranferencia Bs',
                    icon: 'pi pi-send',
                    command: () => {
                        this.Seleccion(1);
                    }
                },
            ],
            cuentas: [
                {
                    code:1, 
                    name:'Binance',
                    detalle: '<p>payID: 123378616</p>'

                },
                {
                    code:2, 
                    name:'Pago Movil Bs.',
                    detalle: '<p>Mercantil 0105 </br>C.I. 10516105 </br>Celular 04241356267</p>'            
                },
            ],
            seleccion: null,
            moneda: null,

		}
	},
	created() {
        this.$store.commit('Tasa');
    },
	methods: {
        Seleccion(id){
            if(id) {
                this.moneda = 'Bs.';
                this.datos.monto = parseFloat(this.curso.precio*this.$store.state.tasa);
            } else {
                this.moneda = 'USDT';
                this.datos.monto = this.curso.precio;
            }
            this.seleccion = this.cuentas[id];


        },
        VerPDF(document){
            window.open('/pdf/'+document, '_blank');
		},
        Procesar() {
            if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.
					test(this.datos.email)) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: this.$t('Error en Correo'), life: 10000});
				} else if (this.datos.name == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: this.$t('Error en Nombre'), life: 5000});
				} else {
                    this.datos.pago = this.seleccion;
                    Consulta.Procesar('Pago',this.datos).then(response => {
                        //this.$store.state.error =response;
                        this.$store.commit('Loading');
                        this.$toast.add(Consulta.Mens(response.result));
                        if (response.result) {
                            this.$store.state.pago = false;
                        }
                    });
                }
		},
    




        
	},
	watch: {
	},
	computed: {
	}
}
</script>
<style>
</style>